/*** Reset ***/
html,
body,
div {
  border: 0;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow: hidden;
}

/* IE10+ */
::-ms-clear {
  display: none;
}

/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
button.mat-menu-item {
  line-height: 24px !important;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.mat-icon svg {
  height: 24px;
  width: 24px;
}

/*** IMPORTED FILE NOT FOUND ***/
.shepherd-button {
  background: #3288e6;
  border: 0;
  border-radius: 3px;
  color: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0.5rem 1.5rem;
  transition: all 0.5s ease;
}

.shepherd-button:not(:disabled):hover {
  background: #196fcc;
  color: hsla(0, 0%, 100%, 0.75);
}

.shepherd-button.shepherd-button-secondary {
  background: #f1f2f3;
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-button.shepherd-button-secondary:not(:disabled):hover {
  background: #d6d9db;
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-button:disabled {
  cursor: not-allowed;
}

.shepherd-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  padding: 0 0.75rem 0.75rem;
}

.shepherd-footer .shepherd-button:last-child {
  margin-right: 0;
}

.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: hsla(0, 0%, 50.2%, 0.75);
  font-size: 2em;
  cursor: pointer;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color 0.5s ease;
}

.shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: hsla(0, 0%, 50.2%, 0.75);
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-title {
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
}

.shepherd-header {
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  line-height: 2em;
  padding: 0.75rem 0.75rem 0;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: #e6e6e6;
  padding: 1em;
}

.shepherd-text {
  color: rgba(0, 0, 0, 0.75);
  font-size: 1rem;
  line-height: 1.3em;
  padding: 0.75em;
}

.shepherd-text p {
  margin-top: 0;
}

.shepherd-text p:last-child {
  margin-bottom: 0;
}

.shepherd-content {
  border-radius: 5px;
  outline: none;
  padding: 0;
}

.shepherd-element {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.shepherd-enabled.shepherd-element {
  opacity: 1;
  visibility: visible;
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.shepherd-element, .shepherd-element *, .shepherd-element :after, .shepherd-element :before {
  box-sizing: border-box;
}

.shepherd-arrow, .shepherd-arrow:before {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: -1;
}

.shepherd-arrow:before {
  content: "";
  transform: rotate(45deg);
  background: #fff;
}

.shepherd-element[data-popper-placement^=top] > .shepherd-arrow {
  bottom: -8px;
}

.shepherd-element[data-popper-placement^=bottom] > .shepherd-arrow {
  top: -8px;
}

.shepherd-element[data-popper-placement^=left] > .shepherd-arrow {
  right: -8px;
}

.shepherd-element[data-popper-placement^=right] > .shepherd-arrow {
  left: -8px;
}

.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0;
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow:before {
  background-color: #e6e6e6;
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target, .shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}

.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: 9997;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 0.5;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all;
}

/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 2px solid blue;
}

.ol-mouse-position {
  top: 8px;
  right: 8px;
  position: absolute;
}

.ol-scale-line {
  background: rgba(0, 60, 136, 0.3);
  border-radius: 4px;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  position: absolute;
}

.ol-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 10px;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
  transition: all 0.25s;
}

.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-step-marker {
  width: 1px;
  height: 15px;
  background-color: #000000;
  float: right;
  z-Index: 10;
}

.ol-scale-step-text {
  position: absolute;
  bottom: -5px;
  font-size: 12px;
  z-Index: 11;
  color: #000000;
  text-shadow: -2px 0 #FFFFFF, 0 2px #FFFFFF, 2px 0 #FFFFFF, 0 -2px #FFFFFF;
}

.ol-scale-text {
  position: absolute;
  font-size: 14px;
  text-align: center;
  bottom: 25px;
  color: #000000;
  text-shadow: -2px 0 #FFFFFF, 0 2px #FFFFFF, 2px 0 #FFFFFF, 0 -2px #FFFFFF;
}

.ol-scale-singlebar {
  position: relative;
  height: 10px;
  z-Index: 9;
  border: 1px solid black;
}

.ol-unsupported {
  display: none;
}

.ol-viewport, .ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ol-overlaycontainer, .ol-overlaycontainer-stopevent {
  pointer-events: none;
}

.ol-overlaycontainer > *, .ol-overlaycontainer-stopevent > * {
  pointer-events: auto;
}

.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}

.ol-control:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.ol-zoom {
  top: 0.5em;
  left: 0.5em;
}

.ol-rotate {
  top: 0.5em;
  right: 0.5em;
  transition: opacity 0.25s linear, visibility 0s linear;
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s linear, visibility 0s linear 0.25s;
}

.ol-zoom-extent {
  top: 4.643em;
  left: 0.5em;
}

.ol-full-screen {
  right: 0.5em;
  top: 0.5em;
}

.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: white;
  font-size: 1.14em;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: 0.4em;
  background-color: rgba(0, 60, 136, 0.5);
  border: none;
  border-radius: 2px;
}

.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}

.ol-control button span {
  pointer-events: none;
}

.ol-zoom-extent button {
  line-height: 1.4em;
}

.ol-compass {
  display: block;
  font-weight: normal;
  font-size: 1.2em;
  will-change: transform;
}

.ol-touch .ol-control button {
  font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
  top: 5.5em;
}

.ol-control button:hover,
.ol-control button:focus {
  text-decoration: none;
  background-color: rgba(0, 60, 136, 0.7);
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}

.ol-attribution {
  text-align: right;
  bottom: 0.5em;
  right: 0.5em;
  max-width: calc(100% - 1.3em);
}

.ol-attribution ul {
  margin: 0;
  padding: 0 0.5em;
  color: #000;
  text-shadow: 0 0 2px #fff;
}

.ol-attribution li {
  display: inline;
  list-style: none;
}

.ol-attribution li:not(:last-child):after {
  content: " ";
}

.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}

.ol-attribution ul, .ol-attribution button {
  display: inline-block;
}

.ol-attribution.ol-collapsed ul {
  display: none;
}

.ol-attribution:not(.ol-collapsed) {
  background: rgba(255, 255, 255, 0.8);
}

.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0;
}

.ol-attribution.ol-uncollapsible img {
  margin-top: -0.2em;
  max-height: 1.6em;
}

.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  top: 4.5em;
  left: 0.5em;
  height: 200px;
}

.ol-zoomslider button {
  position: relative;
  height: 10px;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  left: 0.5em;
  bottom: 0.5em;
}

.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: 0 4px 0 0;
}

.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: inline-block;
}

.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid #7b98bc;
  height: 150px;
  margin: 2px;
  width: 150px;
}

.ol-overviewmap:not(.ol-collapsed) button {
  bottom: 1px;
  left: 2px;
  position: absolute;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
  background: rgba(255, 255, 255, 0.8);
}

.ol-overviewmap-box {
  border: 2px dotted rgba(0, 60, 136, 0.7);
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}

/*** --- ***/
.ctpq-theme .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.ctpq-theme .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.ctpq-theme .mat-h1, .ctpq-theme .mat-headline, .ctpq-theme .mat-typography h1 {
  font: 400 22px/30px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.ctpq-theme .mat-h2, .ctpq-theme .mat-title, .ctpq-theme .mat-typography h2 {
  font: 500 18px/30px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.ctpq-theme .mat-h3, .ctpq-theme .mat-subheading-2, .ctpq-theme .mat-typography h3 {
  font: 400 14px/26px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.ctpq-theme .mat-h4, .ctpq-theme .mat-subheading-1, .ctpq-theme .mat-typography h4 {
  font: 400 13px/22px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.ctpq-theme .mat-h5, .ctpq-theme .mat-typography h5 {
  font: 400 calc(12px * 0.83)/18px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.ctpq-theme .mat-h6, .ctpq-theme .mat-typography h6 {
  font: 400 calc(12px * 0.67)/18px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.ctpq-theme .mat-body-strong, .ctpq-theme .mat-body-2 {
  font: 500 12px/22px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.ctpq-theme .mat-body, .ctpq-theme .mat-body-1, .ctpq-theme .mat-typography {
  font: 400 12px/18px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.ctpq-theme .mat-body p, .ctpq-theme .mat-body-1 p, .ctpq-theme .mat-typography p {
  margin: 0 0 12px;
}
.ctpq-theme .mat-small, .ctpq-theme .mat-caption {
  font: 400 12px/18px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.ctpq-theme .mat-display-4, .ctpq-theme .mat-typography .mat-display-4 {
  font: 300 110px/110px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 56px;
}
.ctpq-theme .mat-display-3, .ctpq-theme .mat-typography .mat-display-3 {
  font: 400 54px/54px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.ctpq-theme .mat-display-2, .ctpq-theme .mat-typography .mat-display-2 {
  font: 400 43px/46px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.ctpq-theme .mat-display-1, .ctpq-theme .mat-typography .mat-display-1 {
  font: 400 32px/38px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.ctpq-theme .mat-bottom-sheet-container {
  font: 400 12px/18px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.ctpq-theme .mat-button, .ctpq-theme .mat-raised-button, .ctpq-theme .mat-icon-button, .ctpq-theme .mat-stroked-button,
.ctpq-theme .mat-flat-button, .ctpq-theme .mat-fab, .ctpq-theme .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-weight: 500;
}
.ctpq-theme .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-card-title {
  font-size: 22px;
  font-weight: 500;
}
.ctpq-theme .mat-card-header .mat-card-title {
  font-size: 18px;
}
.ctpq-theme .mat-card-subtitle,
.ctpq-theme .mat-card-content {
  font-size: 12px;
}
.ctpq-theme .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-checkbox-layout .mat-checkbox-label {
  line-height: 22px;
}
.ctpq-theme .mat-chip {
  font-size: 12px;
  font-weight: 500;
}
.ctpq-theme .mat-chip .mat-chip-trailing-icon.mat-icon,
.ctpq-theme .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.ctpq-theme .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.ctpq-theme .mat-cell, .ctpq-theme .mat-footer-cell {
  font-size: 12px;
}
.ctpq-theme .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-calendar-body {
  font-size: 13px;
}
.ctpq-theme .mat-calendar-body-label,
.ctpq-theme .mat-calendar-period-button {
  font-size: 13px;
  font-weight: 500;
}
.ctpq-theme .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.ctpq-theme .mat-dialog-title {
  font: 500 18px/30px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.ctpq-theme .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-weight: 400;
}
.ctpq-theme .mat-expansion-panel-content {
  font: 400 12px/18px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.ctpq-theme .mat-form-field {
  font: 400 14px/1.25 Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.ctpq-theme .mat-form-field-wrapper {
  padding-bottom: 1.4375em;
}
.ctpq-theme .mat-form-field-prefix .mat-icon,
.ctpq-theme .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.25;
}
.ctpq-theme .mat-form-field-prefix .mat-icon-button,
.ctpq-theme .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.ctpq-theme .mat-form-field-prefix .mat-icon-button .mat-icon,
.ctpq-theme .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.25em;
  line-height: 1.25;
}
.ctpq-theme .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.9375em solid transparent;
}
.ctpq-theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.ctpq-theme .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.4375em) scale(0.75);
  width: 133.3333333333%;
}
.ctpq-theme .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.43749em) scale(0.75);
  width: 133.3333433333%;
}
.ctpq-theme .mat-form-field-label-wrapper {
  top: -0.9375em;
  padding-top: 0.9375em;
}
.ctpq-theme .mat-form-field-label {
  top: 1.4375em;
}
.ctpq-theme .mat-form-field-underline {
  bottom: 1.4375em;
}
.ctpq-theme .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.9166666667em);
}
.ctpq-theme .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.ctpq-theme .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.375em 0;
}
.ctpq-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.ctpq-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.3125em) scale(0.75);
  width: 133.3333333333%;
}
.ctpq-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.31249em) scale(0.75);
  width: 133.3333433333%;
}
.ctpq-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.31248em) scale(0.75);
  width: 133.3333533333%;
}
.ctpq-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.3125em;
}
.ctpq-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.ctpq-theme .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.4166666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .ctpq-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.ctpq-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.31247em) scale(0.75);
  }
  .ctpq-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.31246em) scale(0.75);
  }
  .ctpq-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.31245em) scale(0.75);
  }
}
.ctpq-theme .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.ctpq-theme .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.1875em;
  margin-top: -0.5em;
}
.ctpq-theme .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.ctpq-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.6875em) scale(0.75);
  width: 133.3333333333%;
}
.ctpq-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.68749em) scale(0.75);
  width: 133.3333433333%;
}
.ctpq-theme .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.ctpq-theme .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.9375em;
  margin-top: -0.25em;
}
.ctpq-theme .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.ctpq-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.6875em) scale(0.75);
  width: 133.3333333333%;
}
.ctpq-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.68749em) scale(0.75);
  width: 133.3333433333%;
}
.ctpq-theme .mat-grid-tile-header,
.ctpq-theme .mat-grid-tile-footer {
  font-size: 12px;
}
.ctpq-theme .mat-grid-tile-header .mat-line,
.ctpq-theme .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.ctpq-theme .mat-grid-tile-header .mat-line:nth-child(n+2),
.ctpq-theme .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.ctpq-theme input.mat-input-element {
  margin-top: -0.125em;
}
.ctpq-theme .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.ctpq-theme .mat-paginator,
.ctpq-theme .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.ctpq-theme .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-select-trigger {
  height: 1.25em;
}
.ctpq-theme .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.ctpq-theme .mat-stepper-vertical, .ctpq-theme .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-step-label {
  font-size: 12px;
  font-weight: 400;
}
.ctpq-theme .mat-step-sub-label-error {
  font-weight: normal;
}
.ctpq-theme .mat-step-label-error {
  font-size: 12px;
}
.ctpq-theme .mat-step-label-selected {
  font-size: 12px;
  font-weight: 500;
}
.ctpq-theme .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-tab-label, .ctpq-theme .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-weight: 500;
}
.ctpq-theme .mat-toolbar,
.ctpq-theme .mat-toolbar h1,
.ctpq-theme .mat-toolbar h2,
.ctpq-theme .mat-toolbar h3,
.ctpq-theme .mat-toolbar h4,
.ctpq-theme .mat-toolbar h5,
.ctpq-theme .mat-toolbar h6 {
  font: 500 18px/30px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.ctpq-theme .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.ctpq-theme .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.ctpq-theme .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-list-base .mat-list-item {
  font-size: 14px;
}
.ctpq-theme .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.ctpq-theme .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.ctpq-theme .mat-list-base .mat-list-option {
  font-size: 14px;
}
.ctpq-theme .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.ctpq-theme .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.ctpq-theme .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.ctpq-theme .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.ctpq-theme .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.ctpq-theme .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.ctpq-theme .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.ctpq-theme .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.ctpq-theme .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.ctpq-theme .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.ctpq-theme .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.ctpq-theme .mat-optgroup-label {
  font: 500 12px/22px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.ctpq-theme .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.ctpq-theme .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.ctpq-theme .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme .mat-tree-node,
.ctpq-theme .mat-nested-tree-node {
  font-weight: 400;
  font-size: 12px;
}
.ctpq-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.ctpq-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.ctpq-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.ctpq-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .ctpq-theme .mat-ripple-element {
  display: none;
}

.ctpq-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.ctpq-theme .cdk-overlay-container, .ctpq-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.ctpq-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.ctpq-theme .cdk-overlay-container:empty {
  display: none;
}
.ctpq-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.ctpq-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.ctpq-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.ctpq-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .ctpq-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}
.ctpq-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.ctpq-theme .cdk-overlay-transparent-backdrop, .ctpq-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}
.ctpq-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.ctpq-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.ctpq-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.ctpq-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.ctpq-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.ctpq-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.ctpq-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
.ctpq-theme .mat-focus-indicator {
  position: relative;
}
.ctpq-theme .mat-mdc-focus-indicator {
  position: relative;
}
.ctpq-theme .mat-ripple-element {
  background-color: rgba(33, 33, 33, 0.1);
}
.ctpq-theme .mat-option {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-option:hover:not(.mat-option-disabled), .ctpq-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.ctpq-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.ctpq-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-option.mat-option-disabled {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #095797;
}
.ctpq-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #23847e;
}
.ctpq-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cb381f;
}
.ctpq-theme .mat-optgroup-label {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-pseudo-checkbox {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.ctpq-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.ctpq-theme .mat-primary .mat-pseudo-checkbox-checked,
.ctpq-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #095797;
}
.ctpq-theme .mat-pseudo-checkbox-checked,
.ctpq-theme .mat-pseudo-checkbox-indeterminate,
.ctpq-theme .mat-accent .mat-pseudo-checkbox-checked,
.ctpq-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #23847e;
}
.ctpq-theme .mat-warn .mat-pseudo-checkbox-checked,
.ctpq-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #cb381f;
}
.ctpq-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.ctpq-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.ctpq-theme .mat-app-background, .ctpq-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(33, 33, 33, 0.2), 0px 0px 0px 0px rgba(33, 33, 33, 0.14), 0px 0px 0px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(33, 33, 33, 0.2), 0px 1px 1px 0px rgba(33, 33, 33, 0.14), 0px 1px 3px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(33, 33, 33, 0.2), 0px 2px 2px 0px rgba(33, 33, 33, 0.14), 0px 1px 5px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(33, 33, 33, 0.2), 0px 3px 4px 0px rgba(33, 33, 33, 0.14), 0px 1px 8px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(33, 33, 33, 0.2), 0px 4px 5px 0px rgba(33, 33, 33, 0.14), 0px 1px 10px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(33, 33, 33, 0.2), 0px 5px 8px 0px rgba(33, 33, 33, 0.14), 0px 1px 14px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(33, 33, 33, 0.2), 0px 6px 10px 0px rgba(33, 33, 33, 0.14), 0px 1px 18px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(33, 33, 33, 0.2), 0px 7px 10px 1px rgba(33, 33, 33, 0.14), 0px 2px 16px 1px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(33, 33, 33, 0.2), 0px 8px 10px 1px rgba(33, 33, 33, 0.14), 0px 3px 14px 2px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(33, 33, 33, 0.2), 0px 9px 12px 1px rgba(33, 33, 33, 0.14), 0px 3px 16px 2px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(33, 33, 33, 0.2), 0px 10px 14px 1px rgba(33, 33, 33, 0.14), 0px 4px 18px 3px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(33, 33, 33, 0.2), 0px 11px 15px 1px rgba(33, 33, 33, 0.14), 0px 4px 20px 3px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(33, 33, 33, 0.2), 0px 12px 17px 2px rgba(33, 33, 33, 0.14), 0px 5px 22px 4px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(33, 33, 33, 0.2), 0px 13px 19px 2px rgba(33, 33, 33, 0.14), 0px 5px 24px 4px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(33, 33, 33, 0.2), 0px 14px 21px 2px rgba(33, 33, 33, 0.14), 0px 5px 26px 4px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(33, 33, 33, 0.2), 0px 15px 22px 2px rgba(33, 33, 33, 0.14), 0px 6px 28px 5px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(33, 33, 33, 0.2), 0px 16px 24px 2px rgba(33, 33, 33, 0.14), 0px 6px 30px 5px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(33, 33, 33, 0.2), 0px 17px 26px 2px rgba(33, 33, 33, 0.14), 0px 6px 32px 5px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(33, 33, 33, 0.2), 0px 18px 28px 2px rgba(33, 33, 33, 0.14), 0px 7px 34px 6px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(33, 33, 33, 0.2), 0px 19px 29px 2px rgba(33, 33, 33, 0.14), 0px 7px 36px 6px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(33, 33, 33, 0.2), 0px 20px 31px 3px rgba(33, 33, 33, 0.14), 0px 8px 38px 7px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(33, 33, 33, 0.2), 0px 21px 33px 3px rgba(33, 33, 33, 0.14), 0px 8px 40px 7px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(33, 33, 33, 0.2), 0px 22px 35px 3px rgba(33, 33, 33, 0.14), 0px 8px 42px 7px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(33, 33, 33, 0.2), 0px 23px 36px 3px rgba(33, 33, 33, 0.14), 0px 9px 44px 8px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(33, 33, 33, 0.2), 0px 24px 38px 3px rgba(33, 33, 33, 0.14), 0px 9px 46px 8px rgba(33, 33, 33, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.ctpq-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(33, 33, 33, 0.2), 0px 4px 5px 0px rgba(33, 33, 33, 0.14), 0px 1px 10px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.ctpq-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-badge-content {
  color: white;
  background: #095797;
}
.cdk-high-contrast-active .ctpq-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.ctpq-theme .mat-badge-accent .mat-badge-content {
  background: #23847e;
  color: white;
}
.ctpq-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #cb381f;
}
.ctpq-theme .mat-badge {
  position: relative;
}
.ctpq-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.ctpq-theme .mat-badge-disabled .mat-badge-content {
  background: #c2c2c2;
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.ctpq-theme .ng-animate-disabled .mat-badge-content,
.ctpq-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.ctpq-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.ctpq-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.ctpq-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.ctpq-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.ctpq-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .ctpq-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.ctpq-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .ctpq-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.ctpq-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .ctpq-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.ctpq-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .ctpq-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.ctpq-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.ctpq-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.ctpq-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.ctpq-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .ctpq-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.ctpq-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .ctpq-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.ctpq-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .ctpq-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.ctpq-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .ctpq-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.ctpq-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.ctpq-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.ctpq-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.ctpq-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .ctpq-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.ctpq-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .ctpq-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.ctpq-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .ctpq-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.ctpq-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .ctpq-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.ctpq-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(33, 33, 33, 0.2), 0px 16px 24px 2px rgba(33, 33, 33, 0.14), 0px 6px 30px 5px rgba(33, 33, 33, 0.12);
  background: white;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-button, .ctpq-theme .mat-icon-button, .ctpq-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.ctpq-theme .mat-button.mat-primary, .ctpq-theme .mat-icon-button.mat-primary, .ctpq-theme .mat-stroked-button.mat-primary {
  color: #095797;
}
.ctpq-theme .mat-button.mat-accent, .ctpq-theme .mat-icon-button.mat-accent, .ctpq-theme .mat-stroked-button.mat-accent {
  color: #23847e;
}
.ctpq-theme .mat-button.mat-warn, .ctpq-theme .mat-icon-button.mat-warn, .ctpq-theme .mat-stroked-button.mat-warn {
  color: #cb381f;
}
.ctpq-theme .mat-button.mat-primary.mat-button-disabled, .ctpq-theme .mat-button.mat-accent.mat-button-disabled, .ctpq-theme .mat-button.mat-warn.mat-button-disabled, .ctpq-theme .mat-button.mat-button-disabled.mat-button-disabled, .ctpq-theme .mat-icon-button.mat-primary.mat-button-disabled, .ctpq-theme .mat-icon-button.mat-accent.mat-button-disabled, .ctpq-theme .mat-icon-button.mat-warn.mat-button-disabled, .ctpq-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .ctpq-theme .mat-stroked-button.mat-primary.mat-button-disabled, .ctpq-theme .mat-stroked-button.mat-accent.mat-button-disabled, .ctpq-theme .mat-stroked-button.mat-warn.mat-button-disabled, .ctpq-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(33, 33, 33, 0.26);
}
.ctpq-theme .mat-button.mat-primary .mat-button-focus-overlay, .ctpq-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .ctpq-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #095797;
}
.ctpq-theme .mat-button.mat-accent .mat-button-focus-overlay, .ctpq-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .ctpq-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #23847e;
}
.ctpq-theme .mat-button.mat-warn .mat-button-focus-overlay, .ctpq-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .ctpq-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #cb381f;
}
.ctpq-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .ctpq-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .ctpq-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.ctpq-theme .mat-button .mat-ripple-element, .ctpq-theme .mat-icon-button .mat-ripple-element, .ctpq-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.ctpq-theme .mat-button-focus-overlay {
  background: #212121;
}
.ctpq-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-flat-button, .ctpq-theme .mat-raised-button, .ctpq-theme .mat-fab, .ctpq-theme .mat-mini-fab {
  color: rgba(33, 33, 33, 0.87);
  background-color: white;
}
.ctpq-theme .mat-flat-button.mat-primary, .ctpq-theme .mat-raised-button.mat-primary, .ctpq-theme .mat-fab.mat-primary, .ctpq-theme .mat-mini-fab.mat-primary {
  color: white;
}
.ctpq-theme .mat-flat-button.mat-accent, .ctpq-theme .mat-raised-button.mat-accent, .ctpq-theme .mat-fab.mat-accent, .ctpq-theme .mat-mini-fab.mat-accent {
  color: white;
}
.ctpq-theme .mat-flat-button.mat-warn, .ctpq-theme .mat-raised-button.mat-warn, .ctpq-theme .mat-fab.mat-warn, .ctpq-theme .mat-mini-fab.mat-warn {
  color: white;
}
.ctpq-theme .mat-flat-button.mat-primary.mat-button-disabled, .ctpq-theme .mat-flat-button.mat-accent.mat-button-disabled, .ctpq-theme .mat-flat-button.mat-warn.mat-button-disabled, .ctpq-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .ctpq-theme .mat-raised-button.mat-primary.mat-button-disabled, .ctpq-theme .mat-raised-button.mat-accent.mat-button-disabled, .ctpq-theme .mat-raised-button.mat-warn.mat-button-disabled, .ctpq-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .ctpq-theme .mat-fab.mat-primary.mat-button-disabled, .ctpq-theme .mat-fab.mat-accent.mat-button-disabled, .ctpq-theme .mat-fab.mat-warn.mat-button-disabled, .ctpq-theme .mat-fab.mat-button-disabled.mat-button-disabled, .ctpq-theme .mat-mini-fab.mat-primary.mat-button-disabled, .ctpq-theme .mat-mini-fab.mat-accent.mat-button-disabled, .ctpq-theme .mat-mini-fab.mat-warn.mat-button-disabled, .ctpq-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(33, 33, 33, 0.26);
}
.ctpq-theme .mat-flat-button.mat-primary, .ctpq-theme .mat-raised-button.mat-primary, .ctpq-theme .mat-fab.mat-primary, .ctpq-theme .mat-mini-fab.mat-primary {
  background-color: #095797;
}
.ctpq-theme .mat-flat-button.mat-accent, .ctpq-theme .mat-raised-button.mat-accent, .ctpq-theme .mat-fab.mat-accent, .ctpq-theme .mat-mini-fab.mat-accent {
  background-color: #23847e;
}
.ctpq-theme .mat-flat-button.mat-warn, .ctpq-theme .mat-raised-button.mat-warn, .ctpq-theme .mat-fab.mat-warn, .ctpq-theme .mat-mini-fab.mat-warn {
  background-color: #cb381f;
}
.ctpq-theme .mat-flat-button.mat-primary.mat-button-disabled, .ctpq-theme .mat-flat-button.mat-accent.mat-button-disabled, .ctpq-theme .mat-flat-button.mat-warn.mat-button-disabled, .ctpq-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .ctpq-theme .mat-raised-button.mat-primary.mat-button-disabled, .ctpq-theme .mat-raised-button.mat-accent.mat-button-disabled, .ctpq-theme .mat-raised-button.mat-warn.mat-button-disabled, .ctpq-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .ctpq-theme .mat-fab.mat-primary.mat-button-disabled, .ctpq-theme .mat-fab.mat-accent.mat-button-disabled, .ctpq-theme .mat-fab.mat-warn.mat-button-disabled, .ctpq-theme .mat-fab.mat-button-disabled.mat-button-disabled, .ctpq-theme .mat-mini-fab.mat-primary.mat-button-disabled, .ctpq-theme .mat-mini-fab.mat-accent.mat-button-disabled, .ctpq-theme .mat-mini-fab.mat-warn.mat-button-disabled, .ctpq-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.ctpq-theme .mat-flat-button.mat-primary .mat-ripple-element, .ctpq-theme .mat-raised-button.mat-primary .mat-ripple-element, .ctpq-theme .mat-fab.mat-primary .mat-ripple-element, .ctpq-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.ctpq-theme .mat-flat-button.mat-accent .mat-ripple-element, .ctpq-theme .mat-raised-button.mat-accent .mat-ripple-element, .ctpq-theme .mat-fab.mat-accent .mat-ripple-element, .ctpq-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.ctpq-theme .mat-flat-button.mat-warn .mat-ripple-element, .ctpq-theme .mat-raised-button.mat-warn .mat-ripple-element, .ctpq-theme .mat-fab.mat-warn .mat-ripple-element, .ctpq-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.ctpq-theme .mat-stroked-button:not([class*=mat-elevation-z]), .ctpq-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(33, 33, 33, 0.2), 0px 0px 0px 0px rgba(33, 33, 33, 0.14), 0px 0px 0px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(33, 33, 33, 0.2), 0px 2px 2px 0px rgba(33, 33, 33, 0.14), 0px 1px 5px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(33, 33, 33, 0.2), 0px 8px 10px 1px rgba(33, 33, 33, 0.14), 0px 3px 14px 2px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(33, 33, 33, 0.2), 0px 0px 0px 0px rgba(33, 33, 33, 0.14), 0px 0px 0px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-fab:not([class*=mat-elevation-z]), .ctpq-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(33, 33, 33, 0.2), 0px 6px 10px 0px rgba(33, 33, 33, 0.14), 0px 1px 18px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .ctpq-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(33, 33, 33, 0.2), 0px 12px 17px 2px rgba(33, 33, 33, 0.14), 0px 5px 22px 4px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .ctpq-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(33, 33, 33, 0.2), 0px 0px 0px 0px rgba(33, 33, 33, 0.14), 0px 0px 0px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-button-toggle-standalone,
.ctpq-theme .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(33, 33, 33, 0.2), 0px 2px 2px 0px rgba(33, 33, 33, 0.14), 0px 1px 5px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.ctpq-theme .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.ctpq-theme .mat-button-toggle {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.ctpq-theme .mat-button-toggle-appearance-standard {
  color: rgba(33, 33, 33, 0.87);
  background: white;
}
.ctpq-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.ctpq-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(33, 33, 33, 0.12);
}
.ctpq-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-button-toggle-disabled {
  color: rgba(33, 33, 33, 0.26);
  background-color: #eeeeee;
}
.ctpq-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.ctpq-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.ctpq-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.ctpq-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(33, 33, 33, 0.12);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.ctpq-theme .mat-card {
  background: white;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(33, 33, 33, 0.2), 0px 1px 1px 0px rgba(33, 33, 33, 0.14), 0px 1px 3px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(33, 33, 33, 0.2), 0px 0px 0px 0px rgba(33, 33, 33, 0.14), 0px 0px 0px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-card-subtitle {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-checkbox-frame {
  border-color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.ctpq-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.ctpq-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.ctpq-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .ctpq-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #095797;
}
.ctpq-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .ctpq-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #23847e;
}
.ctpq-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .ctpq-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #cb381f;
}
.ctpq-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .ctpq-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.ctpq-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.ctpq-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-checkbox .mat-ripple-element {
  background-color: #212121;
}
.ctpq-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.ctpq-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #095797;
}
.ctpq-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.ctpq-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #23847e;
}
.ctpq-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.ctpq-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #cb381f;
}
.ctpq-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(33, 33, 33, 0.87);
  opacity: 0.4;
}
.ctpq-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(33, 33, 33, 0.2), 0px 3px 4px 0px rgba(33, 33, 33, 0.14), 0px 1px 8px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.ctpq-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.ctpq-theme .mat-chip.mat-standard-chip::after {
  background: #212121;
}
.ctpq-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #095797;
  color: white;
}
.ctpq-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.ctpq-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.ctpq-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #cb381f;
  color: white;
}
.ctpq-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.ctpq-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.ctpq-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #23847e;
  color: white;
}
.ctpq-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.ctpq-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.ctpq-theme .mat-table {
  background: white;
}
.ctpq-theme .mat-table thead, .ctpq-theme .mat-table tbody, .ctpq-theme .mat-table tfoot,
.ctpq-theme mat-header-row, .ctpq-theme mat-row, .ctpq-theme mat-footer-row,
.ctpq-theme [mat-header-row], .ctpq-theme [mat-row], .ctpq-theme [mat-footer-row],
.ctpq-theme .mat-table-sticky {
  background: inherit;
}
.ctpq-theme mat-row, .ctpq-theme mat-header-row, .ctpq-theme mat-footer-row,
.ctpq-theme th.mat-header-cell, .ctpq-theme td.mat-cell, .ctpq-theme td.mat-footer-cell {
  border-bottom-color: rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-header-cell {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-cell, .ctpq-theme .mat-footer-cell {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-calendar-arrow {
  border-top-color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-datepicker-toggle,
.ctpq-theme .mat-datepicker-content .mat-calendar-next-button,
.ctpq-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-calendar-table-header {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-calendar-table-header-divider::after {
  background: rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-calendar-body-label {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-calendar-body-cell-content,
.ctpq-theme .mat-date-range-input-separator {
  color: rgba(33, 33, 33, 0.87);
  border-color: transparent;
}
.ctpq-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.ctpq-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.ctpq-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.ctpq-theme .mat-calendar-body-in-preview {
  color: rgba(33, 33, 33, 0.24);
}
.ctpq-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(33, 33, 33, 0.18);
}
.ctpq-theme .mat-calendar-body-in-range::before {
  background: rgba(9, 87, 151, 0.2);
}
.ctpq-theme .mat-calendar-body-comparison-identical,
.ctpq-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.ctpq-theme .mat-calendar-body-comparison-bridge-start::before,
.ctpq-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(9, 87, 151, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ctpq-theme .mat-calendar-body-comparison-bridge-end::before,
.ctpq-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(9, 87, 151, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ctpq-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.ctpq-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.ctpq-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.ctpq-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.ctpq-theme .mat-calendar-body-selected {
  background-color: #095797;
  color: white;
}
.ctpq-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(9, 87, 151, 0.4);
}
.ctpq-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.ctpq-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(33, 33, 33, 0.2), 0px 4px 5px 0px rgba(33, 33, 33, 0.14), 0px 1px 10px 0px rgba(33, 33, 33, 0.12);
  background-color: white;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(35, 132, 126, 0.2);
}
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.ctpq-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(35, 132, 126, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.ctpq-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(35, 132, 126, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #23847e;
  color: white;
}
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(35, 132, 126, 0.4);
}
.ctpq-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(203, 56, 31, 0.2);
}
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.ctpq-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(203, 56, 31, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.ctpq-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(203, 56, 31, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #cb381f;
  color: white;
}
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(203, 56, 31, 0.4);
}
.ctpq-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.ctpq-theme .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(33, 33, 33, 0.2), 0px 0px 0px 0px rgba(33, 33, 33, 0.14), 0px 0px 0px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-datepicker-toggle-active {
  color: #095797;
}
.ctpq-theme .mat-datepicker-toggle-active.mat-accent {
  color: #23847e;
}
.ctpq-theme .mat-datepicker-toggle-active.mat-warn {
  color: #cb381f;
}
.ctpq-theme .mat-date-range-input-inner[disabled] {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(33, 33, 33, 0.2), 0px 24px 38px 3px rgba(33, 33, 33, 0.14), 0px 9px 46px 8px rgba(33, 33, 33, 0.12);
  background: white;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-divider {
  border-top-color: rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-divider-vertical {
  border-right-color: rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-expansion-panel {
  background: white;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(33, 33, 33, 0.2), 0px 2px 2px 0px rgba(33, 33, 33, 0.14), 0px 1px 5px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-action-row {
  border-top-color: rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .ctpq-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .ctpq-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .ctpq-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.ctpq-theme .mat-expansion-panel-header-title {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-expansion-panel-header-description,
.ctpq-theme .mat-expansion-indicator::after {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(33, 33, 33, 0.26);
}
.ctpq-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.ctpq-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.ctpq-theme .mat-form-field-label {
  color: rgba(33, 33, 33, 0.6);
}
.ctpq-theme .mat-hint {
  color: rgba(33, 33, 33, 0.6);
}
.ctpq-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #095797;
}
.ctpq-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #23847e;
}
.ctpq-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #cb381f;
}
.ctpq-theme .mat-focused .mat-form-field-required-marker {
  color: #23847e;
}
.ctpq-theme .mat-form-field-ripple {
  background-color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #095797;
}
.ctpq-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #23847e;
}
.ctpq-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #cb381f;
}
.ctpq-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #095797;
}
.ctpq-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #23847e;
}
.ctpq-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #cb381f;
}
.ctpq-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #cb381f;
}
.ctpq-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.ctpq-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #cb381f;
}
.ctpq-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.ctpq-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #cb381f;
}
.ctpq-theme .mat-error {
  color: #cb381f;
}
.ctpq-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(33, 33, 33, 0.42);
}
.ctpq-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(33, 33, 33, 0.42) 0%, rgba(33, 33, 33, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.ctpq-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(33, 33, 33, 0.42);
}
.ctpq-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(33, 33, 33, 0.42) 0%, rgba(33, 33, 33, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.ctpq-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(33, 33, 33, 0.04);
}
.ctpq-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(33, 33, 33, 0.02);
}
.ctpq-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(33, 33, 33, 0.42);
}
.ctpq-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.ctpq-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #095797;
}
.ctpq-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #23847e;
}
.ctpq-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #cb381f;
}
.ctpq-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #cb381f;
}
.ctpq-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(33, 33, 33, 0.06);
}
.ctpq-theme .mat-icon.mat-primary {
  color: #095797;
}
.ctpq-theme .mat-icon.mat-accent {
  color: #23847e;
}
.ctpq-theme .mat-icon.mat-warn {
  color: #cb381f;
}
.ctpq-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-input-element:disabled,
.ctpq-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-input-element {
  caret-color: #095797;
}
.ctpq-theme .mat-input-element::placeholder {
  color: rgba(33, 33, 33, 0.42);
}
.ctpq-theme .mat-input-element::-moz-placeholder {
  color: rgba(33, 33, 33, 0.42);
}
.ctpq-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(33, 33, 33, 0.42);
}
.ctpq-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(33, 33, 33, 0.42);
}
.ctpq-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #23847e;
}
.ctpq-theme .mat-form-field.mat-warn .mat-input-element,
.ctpq-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #cb381f;
}
.ctpq-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #cb381f;
}
.ctpq-theme .mat-list-base .mat-list-item {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-list-base .mat-list-option {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-list-base .mat-subheader {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-list-item-disabled {
  background-color: #eeeeee;
}
.ctpq-theme .mat-list-option:hover, .ctpq-theme .mat-list-option:focus,
.ctpq-theme .mat-nav-list .mat-list-item:hover,
.ctpq-theme .mat-nav-list .mat-list-item:focus,
.ctpq-theme .mat-action-list .mat-list-item:hover,
.ctpq-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.ctpq-theme .mat-list-single-selected-option, .ctpq-theme .mat-list-single-selected-option:hover, .ctpq-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.ctpq-theme .mat-menu-panel {
  background: white;
}
.ctpq-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(33, 33, 33, 0.2), 0px 4px 5px 0px rgba(33, 33, 33, 0.14), 0px 1px 10px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-menu-item {
  background: transparent;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-menu-item[disabled], .ctpq-theme .mat-menu-item[disabled]::after {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-menu-item .mat-icon-no-color,
.ctpq-theme .mat-menu-item-submenu-trigger::after {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-menu-item:hover:not([disabled]),
.ctpq-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.ctpq-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.ctpq-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.ctpq-theme .mat-paginator {
  background: white;
}
.ctpq-theme .mat-paginator,
.ctpq-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-paginator-decrement,
.ctpq-theme .mat-paginator-increment {
  border-top: 2px solid rgba(33, 33, 33, 0.54);
  border-right: 2px solid rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-paginator-first,
.ctpq-theme .mat-paginator-last {
  border-top: 2px solid rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.ctpq-theme .mat-icon-button[disabled] .mat-paginator-increment,
.ctpq-theme .mat-icon-button[disabled] .mat-paginator-first,
.ctpq-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(33, 33, 33, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}

.ctpq-theme .mat-progress-bar-background {
  fill: #dae6f0;
}
.ctpq-theme .mat-progress-bar-buffer {
  background-color: #dae6f0;
}
.ctpq-theme .mat-progress-bar-fill::after {
  background-color: #095797;
}
.ctpq-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f09686;
}
.ctpq-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f09686;
}
.ctpq-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #23847e;
}
.ctpq-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #dae6f0;
}
.ctpq-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #dae6f0;
}
.ctpq-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #cb381f;
}
.ctpq-theme .mat-progress-spinner circle, .ctpq-theme .mat-spinner circle {
  stroke: #095797;
}
.ctpq-theme .mat-progress-spinner.mat-accent circle, .ctpq-theme .mat-spinner.mat-accent circle {
  stroke: #23847e;
}
.ctpq-theme .mat-progress-spinner.mat-warn circle, .ctpq-theme .mat-spinner.mat-warn circle {
  stroke: #cb381f;
}
.ctpq-theme .mat-radio-outer-circle {
  border-color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #095797;
}
.ctpq-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.ctpq-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .ctpq-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .ctpq-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #095797;
}
.ctpq-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #23847e;
}
.ctpq-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.ctpq-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .ctpq-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .ctpq-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #23847e;
}
.ctpq-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cb381f;
}
.ctpq-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.ctpq-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .ctpq-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .ctpq-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #cb381f;
}
.ctpq-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.ctpq-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.ctpq-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-radio-button .mat-ripple-element {
  background-color: #212121;
}
.ctpq-theme .mat-select-value {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-select-placeholder {
  color: rgba(33, 33, 33, 0.42);
}
.ctpq-theme .mat-select-disabled .mat-select-value {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-select-arrow {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-select-panel {
  background: white;
}
.ctpq-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(33, 33, 33, 0.2), 0px 4px 5px 0px rgba(33, 33, 33, 0.14), 0px 1px 10px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.ctpq-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #095797;
}
.ctpq-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #23847e;
}
.ctpq-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #cb381f;
}
.ctpq-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #cb381f;
}
.ctpq-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-drawer {
  background-color: white;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.ctpq-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(33, 33, 33, 0.2), 0px 16px 24px 2px rgba(33, 33, 33, 0.14), 0px 6px 30px 5px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-drawer-side {
  border-right: solid 1px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(33, 33, 33, 0.12);
  border-right: none;
}
.ctpq-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(33, 33, 33, 0.12);
  border-right: none;
}
.ctpq-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.ctpq-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #23847e;
}
.ctpq-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(35, 132, 126, 0.54);
}
.ctpq-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #23847e;
}
.ctpq-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #095797;
}
.ctpq-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(9, 87, 151, 0.54);
}
.ctpq-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #095797;
}
.ctpq-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #cb381f;
}
.ctpq-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(203, 56, 31, 0.54);
}
.ctpq-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #cb381f;
}
.ctpq-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #212121;
}
.ctpq-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(33, 33, 33, 0.2), 0px 1px 1px 0px rgba(33, 33, 33, 0.14), 0px 1px 3px 0px rgba(33, 33, 33, 0.12);
  background-color: #fafafa;
}
.ctpq-theme .mat-slide-toggle-bar {
  background-color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-slider-track-background {
  background-color: rgba(33, 33, 33, 0.26);
}
.ctpq-theme .mat-primary .mat-slider-track-fill,
.ctpq-theme .mat-primary .mat-slider-thumb,
.ctpq-theme .mat-primary .mat-slider-thumb-label {
  background-color: #095797;
}
.ctpq-theme .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.ctpq-theme .mat-primary .mat-slider-focus-ring {
  background-color: rgba(9, 87, 151, 0.2);
}
.ctpq-theme .mat-accent .mat-slider-track-fill,
.ctpq-theme .mat-accent .mat-slider-thumb,
.ctpq-theme .mat-accent .mat-slider-thumb-label {
  background-color: #23847e;
}
.ctpq-theme .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.ctpq-theme .mat-accent .mat-slider-focus-ring {
  background-color: rgba(35, 132, 126, 0.2);
}
.ctpq-theme .mat-warn .mat-slider-track-fill,
.ctpq-theme .mat-warn .mat-slider-thumb,
.ctpq-theme .mat-warn .mat-slider-thumb-label {
  background-color: #cb381f;
}
.ctpq-theme .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.ctpq-theme .mat-warn .mat-slider-focus-ring {
  background-color: rgba(203, 56, 31, 0.2);
}
.ctpq-theme .mat-slider:hover .mat-slider-track-background,
.ctpq-theme .cdk-focused .mat-slider-track-background {
  background-color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-slider-disabled .mat-slider-track-background,
.ctpq-theme .mat-slider-disabled .mat-slider-track-fill,
.ctpq-theme .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(33, 33, 33, 0.26);
}
.ctpq-theme .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(33, 33, 33, 0.26);
}
.ctpq-theme .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.ctpq-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.ctpq-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(33, 33, 33, 0.26);
}
.ctpq-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(33, 33, 33, 0.26);
  background-color: transparent;
}
.ctpq-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .ctpq-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .ctpq-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(33, 33, 33, 0.26);
}
.ctpq-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(33, 33, 33, 0.7);
}
.ctpq-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0.7) 2px, transparent 0, transparent);
}
.ctpq-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0.7) 2px, transparent 0, transparent);
}
.ctpq-theme .mat-step-header.cdk-keyboard-focused, .ctpq-theme .mat-step-header.cdk-program-focused, .ctpq-theme .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .ctpq-theme .mat-step-header:hover {
    background: none;
  }
}
.ctpq-theme .mat-step-header .mat-step-label,
.ctpq-theme .mat-step-header .mat-step-optional {
  color: rgba(33, 33, 33, 0.54);
}
.ctpq-theme .mat-step-header .mat-step-icon {
  background-color: rgba(33, 33, 33, 0.54);
  color: white;
}
.ctpq-theme .mat-step-header .mat-step-icon-selected,
.ctpq-theme .mat-step-header .mat-step-icon-state-done,
.ctpq-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #095797;
  color: white;
}
.ctpq-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #cb381f;
}
.ctpq-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #cb381f;
}
.ctpq-theme .mat-stepper-horizontal, .ctpq-theme .mat-stepper-vertical {
  background-color: white;
}
.ctpq-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-horizontal-stepper-header::before,
.ctpq-theme .mat-horizontal-stepper-header::after,
.ctpq-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(33, 33, 33, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.ctpq-theme .mat-sort-header-arrow {
  color: #878787;
}
.ctpq-theme .mat-tab-nav-bar,
.ctpq-theme .mat-tab-header {
  border-bottom: 1px solid rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.ctpq-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(33, 33, 33, 0.12);
  border-bottom: none;
}
.ctpq-theme .mat-tab-label, .ctpq-theme .mat-tab-link {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-tab-label.mat-tab-disabled, .ctpq-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(33, 33, 33, 0.38);
}
.ctpq-theme .mat-tab-group[class*=mat-background-] .mat-tab-header,
.ctpq-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.ctpq-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(218, 230, 240, 0.3);
}
.ctpq-theme .mat-tab-group.mat-primary .mat-ink-bar, .ctpq-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #095797;
}
.ctpq-theme .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .ctpq-theme .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.ctpq-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 150, 134, 0.3);
}
.ctpq-theme .mat-tab-group.mat-accent .mat-ink-bar, .ctpq-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #23847e;
}
.ctpq-theme .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .ctpq-theme .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.ctpq-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(218, 230, 240, 0.3);
}
.ctpq-theme .mat-tab-group.mat-warn .mat-ink-bar, .ctpq-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #cb381f;
}
.ctpq-theme .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .ctpq-theme .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(218, 230, 240, 0.3);
}
.ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-header, .ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-links, .ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #095797;
}
.ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-label, .ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-link, .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.ctpq-theme .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.ctpq-theme .mat-tab-group.mat-background-primary .mat-ripple-element, .ctpq-theme .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 150, 134, 0.3);
}
.ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-header, .ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-links, .ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #23847e;
}
.ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-label, .ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-link, .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.ctpq-theme .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.ctpq-theme .mat-tab-group.mat-background-accent .mat-ripple-element, .ctpq-theme .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(218, 230, 240, 0.3);
}
.ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-header, .ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-links, .ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #cb381f;
}
.ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-label, .ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-link, .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.ctpq-theme .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.ctpq-theme .mat-tab-group.mat-background-warn .mat-ripple-element, .ctpq-theme .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.ctpq-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme .mat-toolbar.mat-primary {
  background: #095797;
  color: white;
}
.ctpq-theme .mat-toolbar.mat-accent {
  background: #23847e;
  color: white;
}
.ctpq-theme .mat-toolbar.mat-warn {
  background: #cb381f;
  color: white;
}
.ctpq-theme .mat-toolbar .mat-form-field-underline,
.ctpq-theme .mat-toolbar .mat-form-field-ripple,
.ctpq-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.ctpq-theme .mat-toolbar .mat-form-field-label,
.ctpq-theme .mat-toolbar .mat-focused .mat-form-field-label,
.ctpq-theme .mat-toolbar .mat-select-value,
.ctpq-theme .mat-toolbar .mat-select-arrow,
.ctpq-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.ctpq-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.ctpq-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.ctpq-theme .mat-tree {
  background: white;
}
.ctpq-theme .mat-tree-node,
.ctpq-theme .mat-nested-tree-node {
  color: rgba(33, 33, 33, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}

.ctpq-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(33, 33, 33, 0.2), 0px 6px 10px 0px rgba(33, 33, 33, 0.14), 0px 1px 18px 0px rgba(33, 33, 33, 0.12);
}
.ctpq-theme .mat-simple-snackbar-action {
  color: #23847e;
}
.ctpq-theme div.simple-notification.info {
  color: white;
  background-color: #095797;
}
.ctpq-theme div.simple-notification.info .sn-progress-loader span {
  background-color: #223654;
}
.ctpq-theme div.simple-notification.info svg {
  fill: white;
}
.ctpq-theme div.simple-notification.alert {
  color: white;
  background-color: #23847e;
}
.ctpq-theme div.simple-notification.alert .sn-progress-loader span {
  background-color: #1479cc;
}
.ctpq-theme div.simple-notification.alert svg {
  fill: white;
}
.ctpq-theme div.simple-notification.error {
  color: white;
  background-color: #cb381f;
}
.ctpq-theme div.simple-notification.error .sn-progress-loader span {
  background-color: #064684;
}
.ctpq-theme div.simple-notification.error svg {
  fill: white;
}
.ctpq-theme button.mat-icon-button.mat-button-base[disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.ctpq-theme .igo-actionbar-overlay {
  min-width: inherit !important;
  padding-top: 0;
  padding-bottom: 0;
}
.ctpq-theme .igo-actionbar-overlay .mat-list {
  padding-top: 0;
}
.ctpq-theme .igo-actionbar-overlay .mat-list .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text {
  padding-right: 16px;
}
.ctpq-theme .igo-actionbar-overlay .mat-menu-content:not(:empty) {
  padding-top: 0;
  padding-bottom: 0;
}
.ctpq-theme mat-icon[mat-list-avatar][igocollapse]:hover {
  cursor: pointer;
}
.ctpq-theme mat-icon[mat-list-avatar][igocollapse].igo-chevron {
  width: auto !important;
  height: auto;
  transform: rotateZ(0deg);
  transition: 300ms ease-in-out;
}
.ctpq-theme mat-icon[mat-list-avatar][igocollapse].igo-chevron.collapsed {
  transform: rotateZ(180deg);
  transition: 300ms ease-in-out;
}
.ctpq-theme div.igo-collapsed {
  display: none !important;
}
.ctpq-theme igo-entity-table table.igo-entity-table-with-selection tr.igo-entity-table-row-highlighted {
  background-color: #23847e;
  color: white;
}
.ctpq-theme igo-list [igolistitem][color=primary].igo-list-item-selected > mat-list-item {
  background-color: #095797;
  color: white;
}
.ctpq-theme igo-list [igolistitem][color=accent].igo-list-item-selected > mat-list-item {
  background-color: #23847e;
  color: white;
}
.ctpq-theme igo-list [igolistitem].igo-list-item-disabled > mat-list-item {
  color: rgba(0, 0, 0, 0.38);
}
.ctpq-theme igo-list [igolistitem][color=primary]:not(.igo-list-item-disabled):hover > mat-list-item,
.ctpq-theme igo-list [igolistitem][color=primary].igo-list-item-focused > mat-list-item {
  background-color: #dae6f0;
  color: white;
}
.ctpq-theme igo-list [igolistitem][color=accent]:not(.igo-list-item-disabled):hover > mat-list-item,
.ctpq-theme igo-list [igolistitem][color=accent].igo-list-item-focused > mat-list-item {
  background-color: #f09686;
  color: white;
}
.ctpq-theme igo-panel > div.igo-panel-header {
  background-color: #095797;
  color: white;
}
.ctpq-theme igo-toolbox > igo-actionbar mat-list.mat-list-base igo-actionbar-item mat-list-item:hover {
  background-color: #f09686;
}
.ctpq-theme igo-toolbox > igo-actionbar mat-list.mat-list-base igo-actionbar-item mat-list-item.tool-activated, .ctpq-theme igo-toolbox > igo-actionbar mat-list.mat-list-base igo-actionbar-item mat-list-item.children-tool-activated {
  background-color: #23847e;
  cursor: default;
}
.ctpq-theme igo-toolbox > igo-actionbar mat-list.mat-list-base igo-actionbar-item mat-list-item.tool-activated button, .ctpq-theme igo-toolbox > igo-actionbar mat-list.mat-list-base igo-actionbar-item mat-list-item.children-tool-activated button {
  cursor: default;
}
.ctpq-theme igo-toolbox > igo-actionbar:not(.with-title) > mat-list > #lowChevron:hover, .ctpq-theme igo-toolbox > igo-actionbar:not(.with-title) > mat-list > #topChevron:hover {
  background-color: #f09686;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme igo-toolbox.color-primary > igo-actionbar:not(.with-title) {
  box-shadow: unset;
  background-color: #095797;
}
.ctpq-theme igo-toolbox.color-primary > igo-actionbar:not(.with-title) #lowChevron,
.ctpq-theme igo-toolbox.color-primary > igo-actionbar:not(.with-title) #topChevron {
  background-color: #095797;
  color: white;
}
.ctpq-theme igo-toolbox.color-grey > igo-actionbar:not(.with-title) {
  box-shadow: unset;
  background-color: #737475;
}
.ctpq-theme igo-toolbox.color-grey > igo-actionbar:not(.with-title) #lowChevron,
.ctpq-theme igo-toolbox.color-grey > igo-actionbar:not(.with-title) #topChevron {
  background-color: #737475;
  color: white;
}
.ctpq-theme igo-toolbox.color-primary > igo-actionbar:not(.with-title) mat-list.mat-list-base .mat-list-item.mat-list-item-with-avatar,
.ctpq-theme igo-toolbox.color-grey > igo-actionbar:not(.with-title) mat-list.mat-list-base .mat-list-item.mat-list-item-with-avatar {
  color: white;
}
.ctpq-theme igo-toolbox.color-primary > igo-actionbar:not(.with-title) mat-list.mat-list-base .mat-list-item.mat-list-item-with-avatar:hover,
.ctpq-theme igo-toolbox.color-grey > igo-actionbar:not(.with-title) mat-list.mat-list-base .mat-list-item.mat-list-item-with-avatar:hover {
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme igo-toolbox.color-primary > igo-actionbar:not(.with-title) mat-list.mat-list-base .mat-list-item.mat-list-item-with-avatar.tool-activated, .ctpq-theme igo-toolbox.color-primary > igo-actionbar:not(.with-title) mat-list.mat-list-base .mat-list-item.mat-list-item-with-avatar.children-tool-activated,
.ctpq-theme igo-toolbox.color-grey > igo-actionbar:not(.with-title) mat-list.mat-list-base .mat-list-item.mat-list-item-with-avatar.tool-activated,
.ctpq-theme igo-toolbox.color-grey > igo-actionbar:not(.with-title) mat-list.mat-list-base .mat-list-item.mat-list-item-with-avatar.children-tool-activated {
  background-color: white;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme igo-interactive-tour > button.mat-raised-button.tour-button-tool-icon {
  box-shadow: none;
}
.ctpq-theme igo-interactive-tour > button.tour-button-tool-icon {
  background-color: #095797;
  color: white;
  box-shadow: none;
  border: none;
  border-radius: 50%;
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
}
.ctpq-theme igo-interactive-tour > button.tour-button-tool-icon span.interactive-tour-button-title {
  display: none;
}
.ctpq-theme igo-interactive-tour > button.tour-button-tool {
  background-color: #095797;
  color: white;
  border: none;
}
.ctpq-theme igo-interactive-tour > button.tour-button-tool-icon:hover {
  background-color: white;
  color: #095797;
  cursor: pointer;
}
.ctpq-theme .igo-map-tooltip {
  position: relative;
  border-radius: 4px;
  padding: 4px 8px;
  white-space: nowrap;
}
.ctpq-theme .igo-map-tooltip-draw {
  color: rgba(33, 33, 33, 0.87);
  font-weight: bold;
  font-size: 14px;
}
.ctpq-theme igo-draw-popup-component h1 {
  font-size: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ctpq-theme igo-feature-details > table.igo-striped tbody tr:nth-child(odd) {
  background-color: #e0e0e0;
  color: rgba(33, 33, 33, 0.87);
}
.ctpq-theme mat-datetimepicker-calendar > div.mat-datetimepicker-calendar-header {
  color: #095797;
}
.ctpq-theme mat-datetimepicker-calendar > div.mat-datetimepicker-calendar-content .mat-datetimepicker-calendar-body-active {
  background-color: #dae6f0;
  border-radius: 100%;
}
.ctpq-theme .mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}
.ctpq-theme mat-button-toggle.mat-button-toggle-checked {
  background-color: #23847e;
  color: #424242;
}
.ctpq-theme mat-button-toggle {
  color: black !important;
}
.ctpq-theme igo-layer-legend img:after {
  border-top-color: #095797;
}
.ctpq-theme igo-layer-item.igo-layer-item-focused > mat-list-item {
  background-color: #f09686;
  color: white;
}
.ctpq-theme .mat-menu-opacity-slider {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.5), 0 4px 5px 0 rgba(0, 0, 0, 0.15), 0 1px 10px 0 rgba(0, 0, 0, 0.4);
}
.ctpq-theme igo-map-browser div.ol-attribution > button {
  color: #095797;
  background-color: white;
}
.ctpq-theme igo-map-browser div.ol-attribution > ul {
  font-size: 12px;
}
.ctpq-theme igo-zoom-button > div.igo-zoom-button-container > button.mat-primary {
  color: #095797;
  background-color: white;
}
.ctpq-theme igo-zoom-button > div.igo-zoom-button-container > button.mat-accent {
  color: #23847e;
  background-color: white;
}
.ctpq-theme .igo-map-tooltip {
  position: relative;
  border-radius: 4px;
  padding: 4px 8px;
  white-space: nowrap;
}
.ctpq-theme .igo-map-tooltip-measure {
  background-color: rgba(255, 204, 51, 0.7);
  border: 1px solid #ffcd33;
  color: rgba(33, 33, 33, 0.87);
  font-weight: bold;
  font-size: 14px;
}
.ctpq-theme .igo-map-tooltip-hidden {
  display: none;
}
.ctpq-theme .igo-map-tooltip-measure-by-display {
  display: none;
}
.ctpq-theme igo-directions-form mat-list-item.igo-steps:hover {
  background-color: #f09686;
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
}

#cc-main {
  --cc-font-family: semplicitapro, Roboto, "Helvetica Neue", sans-serif;
}

#cc-main .cm--cloud {
  right: 0;
  left: 0;
  bottom: 0;
  max-width: unset;
  border-radius: 0;
  padding: 1em;
}

#cc-main .cm--cloud .cm__desc {
  max-height: unset;
}

#cc-main a {
  background: none;
  text-decoration: underline;
}

#cc-main a:hover {
  text-decoration: none;
}