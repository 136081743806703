/*** Reset ***/

html,
body,
div {
  border: 0;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow: hidden;
}

/* IE10+ */
::-ms-clear {
  display: none;
}
